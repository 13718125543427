<template>
  <div class="No1Wrap en">
    <div class="content">
      <img class="banner" :src="baseUrl + dataInfo.main_image_id" alt />
      <div class="starWrap">
        <m-star-bar
          class="starBar"
          title="Service facilities:"
          :titleFontSize="16"
          verticalAlign="top"
          titleColor="#333"
          :marginRight="8"
          :num="dataInfo.service_star"
        ></m-star-bar>
        <m-star-bar
          class="starBar"
          title="Comfort factor:"
          :titleFontSize="16"
          verticalAlign="top"
          titleColor="#333"
          :marginRight="8"
          :num="dataInfo.comfortable_star"
        ></m-star-bar>
      </div>
      <div class="titleWrap">
        <span class="title">{{dataInfo.title}}</span>
        <div class="btn" @click="book">
          <img :src="require('../../assets/image/stay_en/book.png')" alt />Book
        </div>
      </div>
      <div class="description">
        <p>{{dataInfo.hotel_info}}</p>
      </div>
      <div class="picsWrap">
        <div class="group" v-for="(item,index) in dataInfo.list" :key="index">
          <img :src="baseUrl + item.photo" alt />
          <span>{{item.title}}</span>
        </div>
        <!-- <div class="group">
          <img :src="require('../../assets/image/stay_en/g1_p2.png')" alt />
          <span>The hotel restaurant</span>
        </div>
        <div class="group">
          <img :src="require('../../assets/image/stay_en/g1_p3.png')" alt />
          <span>The hotel room</span>
        </div>
        <div class="group">
          <img :src="require('../../assets/image/stay_en/g1_p4.png')" alt />
          <span>Swimming pool</span>
        </div>-->
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import StarBar from "@/components/starBar";
import axios from "axios";
import config from "@/utils/config";

export default {
  components: {
    [StarBar.name]: StarBar
  },
  data() {
    return {
      dataInfo: {}
    };
  },
  computed: {
    baseUrl() {
      return config.webBaseUrl;
    }
  },
  mounted() {
    this.getData(config.stayUrl_en);
  },
  watch: {
    // 靠这个方式实现详细信息的加载，接收页签路由传来的参数
    $route(newVal, oldVal) {
      // console.log("监视变化2：", newVal, newVal.path);

      let res = JSON.parse(JSON.stringify(this.$route.params));

      var arr = Object.keys(res);
      if (arr.length > 0) {
        this.dataInfo = JSON.parse(res.item);
        // console.log("子数据：", JSON.parse(res.item), this.dataInfo);
      }
    }
  },
  methods: {
    async getData(url) {
      try {
        let res = await axios.get(url);
        if (res.data.code == "0" && res.data.data instanceof Array) {
          if (res.data.data.length > 0) {
            let tempData = JSON.parse(JSON.stringify(res.data.data));
            this.dataInfo = tempData[0];
            // console.log("详情 res.data.data:", this.dataInfo);
          }
        }
      } catch (e) {
        console.log("res err:", e);
      }
    },
    book() {
      this.$emit("book");
    }
  }
};
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "src/assets/styles/variables.scss";
.No1Wrap {
  > .content {
    > img {
      width: 100%;
      height: 524px;
    }
    .starWrap {
      position: relative;
      height: 38px;
      > .starBar {
        position: absolute;
        padding-top: 10px;
      }
      > .starBar:nth-of-type(1) {
        left: 0;
      }
      > .starBar:nth-of-type(2) {
        right: 0;
      }
    }
    > .titleWrap {
      position: relative;
      margin-top: 45px;
      color: $font-grayDarkDD;
      > .title {
        font-size: 36px;
      }
      > .btn {
        position: absolute;
        width: 129px;
        height: 39px;
        top: 0;
        right: 0px;
        line-height: 39px;
        border-radius: 20px;
        border: 1px solid $main-gray;
        color: $font-grayDarkDD;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        > img {
          width: 21px;
          height: 21px;
          vertical-align: text-bottom;
          margin-right: 9px;
        }
      }
    }
    > .description {
      font-size: 18px;
      line-height: 1.6;
    }
    > .picsWrap {
      > .group {
        display: inline-block;

        > img {
          width: 465px;
          height: 287px;
          display: block;
        }
        > span {
          display: block;
          width: 465px;
          height: 72px;
          line-height: 62px;
          font-size: 28px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      > .group:nth-child(odd) {
        > img {
          margin-right: 35px;
        }
      }
    }
  }
}
</style>
